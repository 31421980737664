@import '~antd/dist/antd.css';
:root {
  --primary-color: #FA3534;
  --primary-color-light-1: rgba(250, 53, 52, 0.06);
  --blue-color: #055EAC;
  --box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  --text-color: #434343;
  --text-color-light-1: #666666;
  --text-color-light-2: #4E5969;
  --text-color-light-3: #86909C;
}
.mt-50 {
  margin-top: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}
.ml-50 {
  margin-left: 50px;
}
.mr-50 {
  margin-right: 50px;
}
.mlr-50 {
  margin-left: 50px;
  margin-right: 50px;
}
.mtb-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.m-50 {
  margin: 50px;
}
.mt-49 {
  margin-top: 49px;
}
.mb-49 {
  margin-bottom: 49px;
}
.ml-49 {
  margin-left: 49px;
}
.mr-49 {
  margin-right: 49px;
}
.mlr-49 {
  margin-left: 49px;
  margin-right: 49px;
}
.mtb-49 {
  margin-top: 49px;
  margin-bottom: 49px;
}
.m-49 {
  margin: 49px;
}
.mt-48 {
  margin-top: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
.ml-48 {
  margin-left: 48px;
}
.mr-48 {
  margin-right: 48px;
}
.mlr-48 {
  margin-left: 48px;
  margin-right: 48px;
}
.mtb-48 {
  margin-top: 48px;
  margin-bottom: 48px;
}
.m-48 {
  margin: 48px;
}
.mt-47 {
  margin-top: 47px;
}
.mb-47 {
  margin-bottom: 47px;
}
.ml-47 {
  margin-left: 47px;
}
.mr-47 {
  margin-right: 47px;
}
.mlr-47 {
  margin-left: 47px;
  margin-right: 47px;
}
.mtb-47 {
  margin-top: 47px;
  margin-bottom: 47px;
}
.m-47 {
  margin: 47px;
}
.mt-46 {
  margin-top: 46px;
}
.mb-46 {
  margin-bottom: 46px;
}
.ml-46 {
  margin-left: 46px;
}
.mr-46 {
  margin-right: 46px;
}
.mlr-46 {
  margin-left: 46px;
  margin-right: 46px;
}
.mtb-46 {
  margin-top: 46px;
  margin-bottom: 46px;
}
.m-46 {
  margin: 46px;
}
.mt-45 {
  margin-top: 45px;
}
.mb-45 {
  margin-bottom: 45px;
}
.ml-45 {
  margin-left: 45px;
}
.mr-45 {
  margin-right: 45px;
}
.mlr-45 {
  margin-left: 45px;
  margin-right: 45px;
}
.mtb-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}
.m-45 {
  margin: 45px;
}
.mt-44 {
  margin-top: 44px;
}
.mb-44 {
  margin-bottom: 44px;
}
.ml-44 {
  margin-left: 44px;
}
.mr-44 {
  margin-right: 44px;
}
.mlr-44 {
  margin-left: 44px;
  margin-right: 44px;
}
.mtb-44 {
  margin-top: 44px;
  margin-bottom: 44px;
}
.m-44 {
  margin: 44px;
}
.mt-43 {
  margin-top: 43px;
}
.mb-43 {
  margin-bottom: 43px;
}
.ml-43 {
  margin-left: 43px;
}
.mr-43 {
  margin-right: 43px;
}
.mlr-43 {
  margin-left: 43px;
  margin-right: 43px;
}
.mtb-43 {
  margin-top: 43px;
  margin-bottom: 43px;
}
.m-43 {
  margin: 43px;
}
.mt-42 {
  margin-top: 42px;
}
.mb-42 {
  margin-bottom: 42px;
}
.ml-42 {
  margin-left: 42px;
}
.mr-42 {
  margin-right: 42px;
}
.mlr-42 {
  margin-left: 42px;
  margin-right: 42px;
}
.mtb-42 {
  margin-top: 42px;
  margin-bottom: 42px;
}
.m-42 {
  margin: 42px;
}
.mt-41 {
  margin-top: 41px;
}
.mb-41 {
  margin-bottom: 41px;
}
.ml-41 {
  margin-left: 41px;
}
.mr-41 {
  margin-right: 41px;
}
.mlr-41 {
  margin-left: 41px;
  margin-right: 41px;
}
.mtb-41 {
  margin-top: 41px;
  margin-bottom: 41px;
}
.m-41 {
  margin: 41px;
}
.mt-40 {
  margin-top: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mlr-40 {
  margin-left: 40px;
  margin-right: 40px;
}
.mtb-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.m-40 {
  margin: 40px;
}
.mt-39 {
  margin-top: 39px;
}
.mb-39 {
  margin-bottom: 39px;
}
.ml-39 {
  margin-left: 39px;
}
.mr-39 {
  margin-right: 39px;
}
.mlr-39 {
  margin-left: 39px;
  margin-right: 39px;
}
.mtb-39 {
  margin-top: 39px;
  margin-bottom: 39px;
}
.m-39 {
  margin: 39px;
}
.mt-38 {
  margin-top: 38px;
}
.mb-38 {
  margin-bottom: 38px;
}
.ml-38 {
  margin-left: 38px;
}
.mr-38 {
  margin-right: 38px;
}
.mlr-38 {
  margin-left: 38px;
  margin-right: 38px;
}
.mtb-38 {
  margin-top: 38px;
  margin-bottom: 38px;
}
.m-38 {
  margin: 38px;
}
.mt-37 {
  margin-top: 37px;
}
.mb-37 {
  margin-bottom: 37px;
}
.ml-37 {
  margin-left: 37px;
}
.mr-37 {
  margin-right: 37px;
}
.mlr-37 {
  margin-left: 37px;
  margin-right: 37px;
}
.mtb-37 {
  margin-top: 37px;
  margin-bottom: 37px;
}
.m-37 {
  margin: 37px;
}
.mt-36 {
  margin-top: 36px;
}
.mb-36 {
  margin-bottom: 36px;
}
.ml-36 {
  margin-left: 36px;
}
.mr-36 {
  margin-right: 36px;
}
.mlr-36 {
  margin-left: 36px;
  margin-right: 36px;
}
.mtb-36 {
  margin-top: 36px;
  margin-bottom: 36px;
}
.m-36 {
  margin: 36px;
}
.mt-35 {
  margin-top: 35px;
}
.mb-35 {
  margin-bottom: 35px;
}
.ml-35 {
  margin-left: 35px;
}
.mr-35 {
  margin-right: 35px;
}
.mlr-35 {
  margin-left: 35px;
  margin-right: 35px;
}
.mtb-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}
.m-35 {
  margin: 35px;
}
.mt-34 {
  margin-top: 34px;
}
.mb-34 {
  margin-bottom: 34px;
}
.ml-34 {
  margin-left: 34px;
}
.mr-34 {
  margin-right: 34px;
}
.mlr-34 {
  margin-left: 34px;
  margin-right: 34px;
}
.mtb-34 {
  margin-top: 34px;
  margin-bottom: 34px;
}
.m-34 {
  margin: 34px;
}
.mt-33 {
  margin-top: 33px;
}
.mb-33 {
  margin-bottom: 33px;
}
.ml-33 {
  margin-left: 33px;
}
.mr-33 {
  margin-right: 33px;
}
.mlr-33 {
  margin-left: 33px;
  margin-right: 33px;
}
.mtb-33 {
  margin-top: 33px;
  margin-bottom: 33px;
}
.m-33 {
  margin: 33px;
}
.mt-32 {
  margin-top: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mlr-32 {
  margin-left: 32px;
  margin-right: 32px;
}
.mtb-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.m-32 {
  margin: 32px;
}
.mt-31 {
  margin-top: 31px;
}
.mb-31 {
  margin-bottom: 31px;
}
.ml-31 {
  margin-left: 31px;
}
.mr-31 {
  margin-right: 31px;
}
.mlr-31 {
  margin-left: 31px;
  margin-right: 31px;
}
.mtb-31 {
  margin-top: 31px;
  margin-bottom: 31px;
}
.m-31 {
  margin: 31px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}
.ml-30 {
  margin-left: 30px;
}
.mr-30 {
  margin-right: 30px;
}
.mlr-30 {
  margin-left: 30px;
  margin-right: 30px;
}
.mtb-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.m-30 {
  margin: 30px;
}
.mt-29 {
  margin-top: 29px;
}
.mb-29 {
  margin-bottom: 29px;
}
.ml-29 {
  margin-left: 29px;
}
.mr-29 {
  margin-right: 29px;
}
.mlr-29 {
  margin-left: 29px;
  margin-right: 29px;
}
.mtb-29 {
  margin-top: 29px;
  margin-bottom: 29px;
}
.m-29 {
  margin: 29px;
}
.mt-28 {
  margin-top: 28px;
}
.mb-28 {
  margin-bottom: 28px;
}
.ml-28 {
  margin-left: 28px;
}
.mr-28 {
  margin-right: 28px;
}
.mlr-28 {
  margin-left: 28px;
  margin-right: 28px;
}
.mtb-28 {
  margin-top: 28px;
  margin-bottom: 28px;
}
.m-28 {
  margin: 28px;
}
.mt-27 {
  margin-top: 27px;
}
.mb-27 {
  margin-bottom: 27px;
}
.ml-27 {
  margin-left: 27px;
}
.mr-27 {
  margin-right: 27px;
}
.mlr-27 {
  margin-left: 27px;
  margin-right: 27px;
}
.mtb-27 {
  margin-top: 27px;
  margin-bottom: 27px;
}
.m-27 {
  margin: 27px;
}
.mt-26 {
  margin-top: 26px;
}
.mb-26 {
  margin-bottom: 26px;
}
.ml-26 {
  margin-left: 26px;
}
.mr-26 {
  margin-right: 26px;
}
.mlr-26 {
  margin-left: 26px;
  margin-right: 26px;
}
.mtb-26 {
  margin-top: 26px;
  margin-bottom: 26px;
}
.m-26 {
  margin: 26px;
}
.mt-25 {
  margin-top: 25px;
}
.mb-25 {
  margin-bottom: 25px;
}
.ml-25 {
  margin-left: 25px;
}
.mr-25 {
  margin-right: 25px;
}
.mlr-25 {
  margin-left: 25px;
  margin-right: 25px;
}
.mtb-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}
.m-25 {
  margin: 25px;
}
.mt-24 {
  margin-top: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.mlr-24 {
  margin-left: 24px;
  margin-right: 24px;
}
.mtb-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.m-24 {
  margin: 24px;
}
.mt-23 {
  margin-top: 23px;
}
.mb-23 {
  margin-bottom: 23px;
}
.ml-23 {
  margin-left: 23px;
}
.mr-23 {
  margin-right: 23px;
}
.mlr-23 {
  margin-left: 23px;
  margin-right: 23px;
}
.mtb-23 {
  margin-top: 23px;
  margin-bottom: 23px;
}
.m-23 {
  margin: 23px;
}
.mt-22 {
  margin-top: 22px;
}
.mb-22 {
  margin-bottom: 22px;
}
.ml-22 {
  margin-left: 22px;
}
.mr-22 {
  margin-right: 22px;
}
.mlr-22 {
  margin-left: 22px;
  margin-right: 22px;
}
.mtb-22 {
  margin-top: 22px;
  margin-bottom: 22px;
}
.m-22 {
  margin: 22px;
}
.mt-21 {
  margin-top: 21px;
}
.mb-21 {
  margin-bottom: 21px;
}
.ml-21 {
  margin-left: 21px;
}
.mr-21 {
  margin-right: 21px;
}
.mlr-21 {
  margin-left: 21px;
  margin-right: 21px;
}
.mtb-21 {
  margin-top: 21px;
  margin-bottom: 21px;
}
.m-21 {
  margin: 21px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.mlr-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.mtb-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.m-20 {
  margin: 20px;
}
.mt-19 {
  margin-top: 19px;
}
.mb-19 {
  margin-bottom: 19px;
}
.ml-19 {
  margin-left: 19px;
}
.mr-19 {
  margin-right: 19px;
}
.mlr-19 {
  margin-left: 19px;
  margin-right: 19px;
}
.mtb-19 {
  margin-top: 19px;
  margin-bottom: 19px;
}
.m-19 {
  margin: 19px;
}
.mt-18 {
  margin-top: 18px;
}
.mb-18 {
  margin-bottom: 18px;
}
.ml-18 {
  margin-left: 18px;
}
.mr-18 {
  margin-right: 18px;
}
.mlr-18 {
  margin-left: 18px;
  margin-right: 18px;
}
.mtb-18 {
  margin-top: 18px;
  margin-bottom: 18px;
}
.m-18 {
  margin: 18px;
}
.mt-17 {
  margin-top: 17px;
}
.mb-17 {
  margin-bottom: 17px;
}
.ml-17 {
  margin-left: 17px;
}
.mr-17 {
  margin-right: 17px;
}
.mlr-17 {
  margin-left: 17px;
  margin-right: 17px;
}
.mtb-17 {
  margin-top: 17px;
  margin-bottom: 17px;
}
.m-17 {
  margin: 17px;
}
.mt-16 {
  margin-top: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mlr-16 {
  margin-left: 16px;
  margin-right: 16px;
}
.mtb-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.m-16 {
  margin: 16px;
}
.mt-15 {
  margin-top: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.mr-15 {
  margin-right: 15px;
}
.mlr-15 {
  margin-left: 15px;
  margin-right: 15px;
}
.mtb-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.m-15 {
  margin: 15px;
}
.mt-14 {
  margin-top: 14px;
}
.mb-14 {
  margin-bottom: 14px;
}
.ml-14 {
  margin-left: 14px;
}
.mr-14 {
  margin-right: 14px;
}
.mlr-14 {
  margin-left: 14px;
  margin-right: 14px;
}
.mtb-14 {
  margin-top: 14px;
  margin-bottom: 14px;
}
.m-14 {
  margin: 14px;
}
.mt-13 {
  margin-top: 13px;
}
.mb-13 {
  margin-bottom: 13px;
}
.ml-13 {
  margin-left: 13px;
}
.mr-13 {
  margin-right: 13px;
}
.mlr-13 {
  margin-left: 13px;
  margin-right: 13px;
}
.mtb-13 {
  margin-top: 13px;
  margin-bottom: 13px;
}
.m-13 {
  margin: 13px;
}
.mt-12 {
  margin-top: 12px;
}
.mb-12 {
  margin-bottom: 12px;
}
.ml-12 {
  margin-left: 12px;
}
.mr-12 {
  margin-right: 12px;
}
.mlr-12 {
  margin-left: 12px;
  margin-right: 12px;
}
.mtb-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.m-12 {
  margin: 12px;
}
.mt-11 {
  margin-top: 11px;
}
.mb-11 {
  margin-bottom: 11px;
}
.ml-11 {
  margin-left: 11px;
}
.mr-11 {
  margin-right: 11px;
}
.mlr-11 {
  margin-left: 11px;
  margin-right: 11px;
}
.mtb-11 {
  margin-top: 11px;
  margin-bottom: 11px;
}
.m-11 {
  margin: 11px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.mlr-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.mtb-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.m-10 {
  margin: 10px;
}
.mt-9 {
  margin-top: 9px;
}
.mb-9 {
  margin-bottom: 9px;
}
.ml-9 {
  margin-left: 9px;
}
.mr-9 {
  margin-right: 9px;
}
.mlr-9 {
  margin-left: 9px;
  margin-right: 9px;
}
.mtb-9 {
  margin-top: 9px;
  margin-bottom: 9px;
}
.m-9 {
  margin: 9px;
}
.mt-8 {
  margin-top: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mlr-8 {
  margin-left: 8px;
  margin-right: 8px;
}
.mtb-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.m-8 {
  margin: 8px;
}
.mt-7 {
  margin-top: 7px;
}
.mb-7 {
  margin-bottom: 7px;
}
.ml-7 {
  margin-left: 7px;
}
.mr-7 {
  margin-right: 7px;
}
.mlr-7 {
  margin-left: 7px;
  margin-right: 7px;
}
.mtb-7 {
  margin-top: 7px;
  margin-bottom: 7px;
}
.m-7 {
  margin: 7px;
}
.mt-6 {
  margin-top: 6px;
}
.mb-6 {
  margin-bottom: 6px;
}
.ml-6 {
  margin-left: 6px;
}
.mr-6 {
  margin-right: 6px;
}
.mlr-6 {
  margin-left: 6px;
  margin-right: 6px;
}
.mtb-6 {
  margin-top: 6px;
  margin-bottom: 6px;
}
.m-6 {
  margin: 6px;
}
.mt-5 {
  margin-top: 5px;
}
.mb-5 {
  margin-bottom: 5px;
}
.ml-5 {
  margin-left: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.mlr-5 {
  margin-left: 5px;
  margin-right: 5px;
}
.mtb-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.m-5 {
  margin: 5px;
}
.mt-4 {
  margin-top: 4px;
}
.mb-4 {
  margin-bottom: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.mr-4 {
  margin-right: 4px;
}
.mlr-4 {
  margin-left: 4px;
  margin-right: 4px;
}
.mtb-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.m-4 {
  margin: 4px;
}
.mt-3 {
  margin-top: 3px;
}
.mb-3 {
  margin-bottom: 3px;
}
.ml-3 {
  margin-left: 3px;
}
.mr-3 {
  margin-right: 3px;
}
.mlr-3 {
  margin-left: 3px;
  margin-right: 3px;
}
.mtb-3 {
  margin-top: 3px;
  margin-bottom: 3px;
}
.m-3 {
  margin: 3px;
}
.mt-2 {
  margin-top: 2px;
}
.mb-2 {
  margin-bottom: 2px;
}
.ml-2 {
  margin-left: 2px;
}
.mr-2 {
  margin-right: 2px;
}
.mlr-2 {
  margin-left: 2px;
  margin-right: 2px;
}
.mtb-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}
.m-2 {
  margin: 2px;
}
.mt-1 {
  margin-top: 1px;
}
.mb-1 {
  margin-bottom: 1px;
}
.ml-1 {
  margin-left: 1px;
}
.mr-1 {
  margin-right: 1px;
}
.mlr-1 {
  margin-left: 1px;
  margin-right: 1px;
}
.mtb-1 {
  margin-top: 1px;
  margin-bottom: 1px;
}
.m-1 {
  margin: 1px;
}
.pt-50 {
  padding-top: 50px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pl-50 {
  padding-left: 50px;
}
.pr-50 {
  padding-right: 50px;
}
.plr-50 {
  padding-left: 50px;
  padding-right: 50px;
}
.ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.p-50 {
  padding: 50px;
}
.pt-49 {
  padding-top: 49px;
}
.pb-49 {
  padding-bottom: 49px;
}
.pl-49 {
  padding-left: 49px;
}
.pr-49 {
  padding-right: 49px;
}
.plr-49 {
  padding-left: 49px;
  padding-right: 49px;
}
.ptb-49 {
  padding-top: 49px;
  padding-bottom: 49px;
}
.p-49 {
  padding: 49px;
}
.pt-48 {
  padding-top: 48px;
}
.pb-48 {
  padding-bottom: 48px;
}
.pl-48 {
  padding-left: 48px;
}
.pr-48 {
  padding-right: 48px;
}
.plr-48 {
  padding-left: 48px;
  padding-right: 48px;
}
.ptb-48 {
  padding-top: 48px;
  padding-bottom: 48px;
}
.p-48 {
  padding: 48px;
}
.pt-47 {
  padding-top: 47px;
}
.pb-47 {
  padding-bottom: 47px;
}
.pl-47 {
  padding-left: 47px;
}
.pr-47 {
  padding-right: 47px;
}
.plr-47 {
  padding-left: 47px;
  padding-right: 47px;
}
.ptb-47 {
  padding-top: 47px;
  padding-bottom: 47px;
}
.p-47 {
  padding: 47px;
}
.pt-46 {
  padding-top: 46px;
}
.pb-46 {
  padding-bottom: 46px;
}
.pl-46 {
  padding-left: 46px;
}
.pr-46 {
  padding-right: 46px;
}
.plr-46 {
  padding-left: 46px;
  padding-right: 46px;
}
.ptb-46 {
  padding-top: 46px;
  padding-bottom: 46px;
}
.p-46 {
  padding: 46px;
}
.pt-45 {
  padding-top: 45px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pl-45 {
  padding-left: 45px;
}
.pr-45 {
  padding-right: 45px;
}
.plr-45 {
  padding-left: 45px;
  padding-right: 45px;
}
.ptb-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}
.p-45 {
  padding: 45px;
}
.pt-44 {
  padding-top: 44px;
}
.pb-44 {
  padding-bottom: 44px;
}
.pl-44 {
  padding-left: 44px;
}
.pr-44 {
  padding-right: 44px;
}
.plr-44 {
  padding-left: 44px;
  padding-right: 44px;
}
.ptb-44 {
  padding-top: 44px;
  padding-bottom: 44px;
}
.p-44 {
  padding: 44px;
}
.pt-43 {
  padding-top: 43px;
}
.pb-43 {
  padding-bottom: 43px;
}
.pl-43 {
  padding-left: 43px;
}
.pr-43 {
  padding-right: 43px;
}
.plr-43 {
  padding-left: 43px;
  padding-right: 43px;
}
.ptb-43 {
  padding-top: 43px;
  padding-bottom: 43px;
}
.p-43 {
  padding: 43px;
}
.pt-42 {
  padding-top: 42px;
}
.pb-42 {
  padding-bottom: 42px;
}
.pl-42 {
  padding-left: 42px;
}
.pr-42 {
  padding-right: 42px;
}
.plr-42 {
  padding-left: 42px;
  padding-right: 42px;
}
.ptb-42 {
  padding-top: 42px;
  padding-bottom: 42px;
}
.p-42 {
  padding: 42px;
}
.pt-41 {
  padding-top: 41px;
}
.pb-41 {
  padding-bottom: 41px;
}
.pl-41 {
  padding-left: 41px;
}
.pr-41 {
  padding-right: 41px;
}
.plr-41 {
  padding-left: 41px;
  padding-right: 41px;
}
.ptb-41 {
  padding-top: 41px;
  padding-bottom: 41px;
}
.p-41 {
  padding: 41px;
}
.pt-40 {
  padding-top: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pl-40 {
  padding-left: 40px;
}
.pr-40 {
  padding-right: 40px;
}
.plr-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.ptb-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.p-40 {
  padding: 40px;
}
.pt-39 {
  padding-top: 39px;
}
.pb-39 {
  padding-bottom: 39px;
}
.pl-39 {
  padding-left: 39px;
}
.pr-39 {
  padding-right: 39px;
}
.plr-39 {
  padding-left: 39px;
  padding-right: 39px;
}
.ptb-39 {
  padding-top: 39px;
  padding-bottom: 39px;
}
.p-39 {
  padding: 39px;
}
.pt-38 {
  padding-top: 38px;
}
.pb-38 {
  padding-bottom: 38px;
}
.pl-38 {
  padding-left: 38px;
}
.pr-38 {
  padding-right: 38px;
}
.plr-38 {
  padding-left: 38px;
  padding-right: 38px;
}
.ptb-38 {
  padding-top: 38px;
  padding-bottom: 38px;
}
.p-38 {
  padding: 38px;
}
.pt-37 {
  padding-top: 37px;
}
.pb-37 {
  padding-bottom: 37px;
}
.pl-37 {
  padding-left: 37px;
}
.pr-37 {
  padding-right: 37px;
}
.plr-37 {
  padding-left: 37px;
  padding-right: 37px;
}
.ptb-37 {
  padding-top: 37px;
  padding-bottom: 37px;
}
.p-37 {
  padding: 37px;
}
.pt-36 {
  padding-top: 36px;
}
.pb-36 {
  padding-bottom: 36px;
}
.pl-36 {
  padding-left: 36px;
}
.pr-36 {
  padding-right: 36px;
}
.plr-36 {
  padding-left: 36px;
  padding-right: 36px;
}
.ptb-36 {
  padding-top: 36px;
  padding-bottom: 36px;
}
.p-36 {
  padding: 36px;
}
.pt-35 {
  padding-top: 35px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pl-35 {
  padding-left: 35px;
}
.pr-35 {
  padding-right: 35px;
}
.plr-35 {
  padding-left: 35px;
  padding-right: 35px;
}
.ptb-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}
.p-35 {
  padding: 35px;
}
.pt-34 {
  padding-top: 34px;
}
.pb-34 {
  padding-bottom: 34px;
}
.pl-34 {
  padding-left: 34px;
}
.pr-34 {
  padding-right: 34px;
}
.plr-34 {
  padding-left: 34px;
  padding-right: 34px;
}
.ptb-34 {
  padding-top: 34px;
  padding-bottom: 34px;
}
.p-34 {
  padding: 34px;
}
.pt-33 {
  padding-top: 33px;
}
.pb-33 {
  padding-bottom: 33px;
}
.pl-33 {
  padding-left: 33px;
}
.pr-33 {
  padding-right: 33px;
}
.plr-33 {
  padding-left: 33px;
  padding-right: 33px;
}
.ptb-33 {
  padding-top: 33px;
  padding-bottom: 33px;
}
.p-33 {
  padding: 33px;
}
.pt-32 {
  padding-top: 32px;
}
.pb-32 {
  padding-bottom: 32px;
}
.pl-32 {
  padding-left: 32px;
}
.pr-32 {
  padding-right: 32px;
}
.plr-32 {
  padding-left: 32px;
  padding-right: 32px;
}
.ptb-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.p-32 {
  padding: 32px;
}
.pt-31 {
  padding-top: 31px;
}
.pb-31 {
  padding-bottom: 31px;
}
.pl-31 {
  padding-left: 31px;
}
.pr-31 {
  padding-right: 31px;
}
.plr-31 {
  padding-left: 31px;
  padding-right: 31px;
}
.ptb-31 {
  padding-top: 31px;
  padding-bottom: 31px;
}
.p-31 {
  padding: 31px;
}
.pt-30 {
  padding-top: 30px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pl-30 {
  padding-left: 30px;
}
.pr-30 {
  padding-right: 30px;
}
.plr-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.ptb-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.p-30 {
  padding: 30px;
}
.pt-29 {
  padding-top: 29px;
}
.pb-29 {
  padding-bottom: 29px;
}
.pl-29 {
  padding-left: 29px;
}
.pr-29 {
  padding-right: 29px;
}
.plr-29 {
  padding-left: 29px;
  padding-right: 29px;
}
.ptb-29 {
  padding-top: 29px;
  padding-bottom: 29px;
}
.p-29 {
  padding: 29px;
}
.pt-28 {
  padding-top: 28px;
}
.pb-28 {
  padding-bottom: 28px;
}
.pl-28 {
  padding-left: 28px;
}
.pr-28 {
  padding-right: 28px;
}
.plr-28 {
  padding-left: 28px;
  padding-right: 28px;
}
.ptb-28 {
  padding-top: 28px;
  padding-bottom: 28px;
}
.p-28 {
  padding: 28px;
}
.pt-27 {
  padding-top: 27px;
}
.pb-27 {
  padding-bottom: 27px;
}
.pl-27 {
  padding-left: 27px;
}
.pr-27 {
  padding-right: 27px;
}
.plr-27 {
  padding-left: 27px;
  padding-right: 27px;
}
.ptb-27 {
  padding-top: 27px;
  padding-bottom: 27px;
}
.p-27 {
  padding: 27px;
}
.pt-26 {
  padding-top: 26px;
}
.pb-26 {
  padding-bottom: 26px;
}
.pl-26 {
  padding-left: 26px;
}
.pr-26 {
  padding-right: 26px;
}
.plr-26 {
  padding-left: 26px;
  padding-right: 26px;
}
.ptb-26 {
  padding-top: 26px;
  padding-bottom: 26px;
}
.p-26 {
  padding: 26px;
}
.pt-25 {
  padding-top: 25px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pl-25 {
  padding-left: 25px;
}
.pr-25 {
  padding-right: 25px;
}
.plr-25 {
  padding-left: 25px;
  padding-right: 25px;
}
.ptb-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.p-25 {
  padding: 25px;
}
.pt-24 {
  padding-top: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.pl-24 {
  padding-left: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.plr-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.ptb-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.p-24 {
  padding: 24px;
}
.pt-23 {
  padding-top: 23px;
}
.pb-23 {
  padding-bottom: 23px;
}
.pl-23 {
  padding-left: 23px;
}
.pr-23 {
  padding-right: 23px;
}
.plr-23 {
  padding-left: 23px;
  padding-right: 23px;
}
.ptb-23 {
  padding-top: 23px;
  padding-bottom: 23px;
}
.p-23 {
  padding: 23px;
}
.pt-22 {
  padding-top: 22px;
}
.pb-22 {
  padding-bottom: 22px;
}
.pl-22 {
  padding-left: 22px;
}
.pr-22 {
  padding-right: 22px;
}
.plr-22 {
  padding-left: 22px;
  padding-right: 22px;
}
.ptb-22 {
  padding-top: 22px;
  padding-bottom: 22px;
}
.p-22 {
  padding: 22px;
}
.pt-21 {
  padding-top: 21px;
}
.pb-21 {
  padding-bottom: 21px;
}
.pl-21 {
  padding-left: 21px;
}
.pr-21 {
  padding-right: 21px;
}
.plr-21 {
  padding-left: 21px;
  padding-right: 21px;
}
.ptb-21 {
  padding-top: 21px;
  padding-bottom: 21px;
}
.p-21 {
  padding: 21px;
}
.pt-20 {
  padding-top: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}
.plr-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.ptb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.p-20 {
  padding: 20px;
}
.pt-19 {
  padding-top: 19px;
}
.pb-19 {
  padding-bottom: 19px;
}
.pl-19 {
  padding-left: 19px;
}
.pr-19 {
  padding-right: 19px;
}
.plr-19 {
  padding-left: 19px;
  padding-right: 19px;
}
.ptb-19 {
  padding-top: 19px;
  padding-bottom: 19px;
}
.p-19 {
  padding: 19px;
}
.pt-18 {
  padding-top: 18px;
}
.pb-18 {
  padding-bottom: 18px;
}
.pl-18 {
  padding-left: 18px;
}
.pr-18 {
  padding-right: 18px;
}
.plr-18 {
  padding-left: 18px;
  padding-right: 18px;
}
.ptb-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}
.p-18 {
  padding: 18px;
}
.pt-17 {
  padding-top: 17px;
}
.pb-17 {
  padding-bottom: 17px;
}
.pl-17 {
  padding-left: 17px;
}
.pr-17 {
  padding-right: 17px;
}
.plr-17 {
  padding-left: 17px;
  padding-right: 17px;
}
.ptb-17 {
  padding-top: 17px;
  padding-bottom: 17px;
}
.p-17 {
  padding: 17px;
}
.pt-16 {
  padding-top: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.plr-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.ptb-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.p-16 {
  padding: 16px;
}
.pt-15 {
  padding-top: 15px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pl-15 {
  padding-left: 15px;
}
.pr-15 {
  padding-right: 15px;
}
.plr-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.ptb-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.p-15 {
  padding: 15px;
}
.pt-14 {
  padding-top: 14px;
}
.pb-14 {
  padding-bottom: 14px;
}
.pl-14 {
  padding-left: 14px;
}
.pr-14 {
  padding-right: 14px;
}
.plr-14 {
  padding-left: 14px;
  padding-right: 14px;
}
.ptb-14 {
  padding-top: 14px;
  padding-bottom: 14px;
}
.p-14 {
  padding: 14px;
}
.pt-13 {
  padding-top: 13px;
}
.pb-13 {
  padding-bottom: 13px;
}
.pl-13 {
  padding-left: 13px;
}
.pr-13 {
  padding-right: 13px;
}
.plr-13 {
  padding-left: 13px;
  padding-right: 13px;
}
.ptb-13 {
  padding-top: 13px;
  padding-bottom: 13px;
}
.p-13 {
  padding: 13px;
}
.pt-12 {
  padding-top: 12px;
}
.pb-12 {
  padding-bottom: 12px;
}
.pl-12 {
  padding-left: 12px;
}
.pr-12 {
  padding-right: 12px;
}
.plr-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.ptb-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.p-12 {
  padding: 12px;
}
.pt-11 {
  padding-top: 11px;
}
.pb-11 {
  padding-bottom: 11px;
}
.pl-11 {
  padding-left: 11px;
}
.pr-11 {
  padding-right: 11px;
}
.plr-11 {
  padding-left: 11px;
  padding-right: 11px;
}
.ptb-11 {
  padding-top: 11px;
  padding-bottom: 11px;
}
.p-11 {
  padding: 11px;
}
.pt-10 {
  padding-top: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pl-10 {
  padding-left: 10px;
}
.pr-10 {
  padding-right: 10px;
}
.plr-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.ptb-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.p-10 {
  padding: 10px;
}
.pt-9 {
  padding-top: 9px;
}
.pb-9 {
  padding-bottom: 9px;
}
.pl-9 {
  padding-left: 9px;
}
.pr-9 {
  padding-right: 9px;
}
.plr-9 {
  padding-left: 9px;
  padding-right: 9px;
}
.ptb-9 {
  padding-top: 9px;
  padding-bottom: 9px;
}
.p-9 {
  padding: 9px;
}
.pt-8 {
  padding-top: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.plr-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.ptb-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.p-8 {
  padding: 8px;
}
.pt-7 {
  padding-top: 7px;
}
.pb-7 {
  padding-bottom: 7px;
}
.pl-7 {
  padding-left: 7px;
}
.pr-7 {
  padding-right: 7px;
}
.plr-7 {
  padding-left: 7px;
  padding-right: 7px;
}
.ptb-7 {
  padding-top: 7px;
  padding-bottom: 7px;
}
.p-7 {
  padding: 7px;
}
.pt-6 {
  padding-top: 6px;
}
.pb-6 {
  padding-bottom: 6px;
}
.pl-6 {
  padding-left: 6px;
}
.pr-6 {
  padding-right: 6px;
}
.plr-6 {
  padding-left: 6px;
  padding-right: 6px;
}
.ptb-6 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.p-6 {
  padding: 6px;
}
.pt-5 {
  padding-top: 5px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pl-5 {
  padding-left: 5px;
}
.pr-5 {
  padding-right: 5px;
}
.plr-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.ptb-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.p-5 {
  padding: 5px;
}
.pt-4 {
  padding-top: 4px;
}
.pb-4 {
  padding-bottom: 4px;
}
.pl-4 {
  padding-left: 4px;
}
.pr-4 {
  padding-right: 4px;
}
.plr-4 {
  padding-left: 4px;
  padding-right: 4px;
}
.ptb-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.p-4 {
  padding: 4px;
}
.pt-3 {
  padding-top: 3px;
}
.pb-3 {
  padding-bottom: 3px;
}
.pl-3 {
  padding-left: 3px;
}
.pr-3 {
  padding-right: 3px;
}
.plr-3 {
  padding-left: 3px;
  padding-right: 3px;
}
.ptb-3 {
  padding-top: 3px;
  padding-bottom: 3px;
}
.p-3 {
  padding: 3px;
}
.pt-2 {
  padding-top: 2px;
}
.pb-2 {
  padding-bottom: 2px;
}
.pl-2 {
  padding-left: 2px;
}
.pr-2 {
  padding-right: 2px;
}
.plr-2 {
  padding-left: 2px;
  padding-right: 2px;
}
.ptb-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}
.p-2 {
  padding: 2px;
}
.pt-1 {
  padding-top: 1px;
}
.pb-1 {
  padding-bottom: 1px;
}
.pl-1 {
  padding-left: 1px;
}
.pr-1 {
  padding-right: 1px;
}
.plr-1 {
  padding-left: 1px;
  padding-right: 1px;
}
.ptb-1 {
  padding-top: 1px;
  padding-bottom: 1px;
}
.p-1 {
  padding: 1px;
}
.font-44 {
  font-size: 44px;
}
.font-43 {
  font-size: 43px;
}
.font-42 {
  font-size: 42px;
}
.font-41 {
  font-size: 41px;
}
.font-40 {
  font-size: 40px;
}
.font-39 {
  font-size: 39px;
}
.font-38 {
  font-size: 38px;
}
.font-37 {
  font-size: 37px;
}
.font-36 {
  font-size: 36px;
}
.font-35 {
  font-size: 35px;
}
.font-34 {
  font-size: 34px;
}
.font-33 {
  font-size: 33px;
}
.font-32 {
  font-size: 32px;
}
.font-31 {
  font-size: 31px;
}
.font-30 {
  font-size: 30px;
}
.font-29 {
  font-size: 29px;
}
.font-28 {
  font-size: 28px;
}
.font-27 {
  font-size: 27px;
}
.font-26 {
  font-size: 26px;
}
.font-25 {
  font-size: 25px;
}
.font-24 {
  font-size: 24px;
}
.font-23 {
  font-size: 23px;
}
.font-22 {
  font-size: 22px;
}
.font-21 {
  font-size: 21px;
}
.font-20 {
  font-size: 20px;
}
.font-19 {
  font-size: 19px;
}
.font-18 {
  font-size: 18px;
}
.font-17 {
  font-size: 17px;
}
.font-16 {
  font-size: 16px;
}
.font-15 {
  font-size: 15px;
}
.font-14 {
  font-size: 14px;
}
.font-13 {
  font-size: 13px;
}
.font-12 {
  font-size: 12px;
}
.font-11 {
  font-size: 11px;
}
.font-10 {
  font-size: 10px;
}
.font-9 {
  font-size: 9px;
}
.font-8 {
  font-size: 8px;
}
.font-7 {
  font-size: 7px;
}
.font-6 {
  font-size: 6px;
}
.font-5 {
  font-size: 5px;
}
.font-4 {
  font-size: 4px;
}
.font-3 {
  font-size: 3px;
}
.font-2 {
  font-size: 2px;
}
.font-1 {
  font-size: 1px;
}
.radius-30 {
  border-radius: 30px;
}
.radius-29 {
  border-radius: 29px;
}
.radius-28 {
  border-radius: 28px;
}
.radius-27 {
  border-radius: 27px;
}
.radius-26 {
  border-radius: 26px;
}
.radius-25 {
  border-radius: 25px;
}
.radius-24 {
  border-radius: 24px;
}
.radius-23 {
  border-radius: 23px;
}
.radius-22 {
  border-radius: 22px;
}
.radius-21 {
  border-radius: 21px;
}
.radius-20 {
  border-radius: 20px;
}
.radius-19 {
  border-radius: 19px;
}
.radius-18 {
  border-radius: 18px;
}
.radius-17 {
  border-radius: 17px;
}
.radius-16 {
  border-radius: 16px;
}
.radius-15 {
  border-radius: 15px;
}
.radius-14 {
  border-radius: 14px;
}
.radius-13 {
  border-radius: 13px;
}
.radius-12 {
  border-radius: 12px;
}
.radius-11 {
  border-radius: 11px;
}
.radius-10 {
  border-radius: 10px;
}
.radius-9 {
  border-radius: 9px;
}
.radius-8 {
  border-radius: 8px;
}
.radius-7 {
  border-radius: 7px;
}
.radius-6 {
  border-radius: 6px;
}
.radius-5 {
  border-radius: 5px;
}
.radius-4 {
  border-radius: 4px;
}
.radius-3 {
  border-radius: 3px;
}
.radius-2 {
  border-radius: 2px;
}
.radius-1 {
  border-radius: 1px;
}
.font-300 {
  font-weight: 300;
}
.font-400 {
  font-weight: 400;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}
.font-700 {
  font-weight: 700;
}
.h-full {
  height: 100%;
}
.h-0 {
  height: 0;
}
.h-50 {
  height: 50px;
}
.h-49 {
  height: 49px;
}
.h-48 {
  height: 48px;
}
.h-47 {
  height: 47px;
}
.h-46 {
  height: 46px;
}
.h-45 {
  height: 45px;
}
.h-44 {
  height: 44px;
}
.h-43 {
  height: 43px;
}
.h-42 {
  height: 42px;
}
.h-41 {
  height: 41px;
}
.h-40 {
  height: 40px;
}
.h-39 {
  height: 39px;
}
.h-38 {
  height: 38px;
}
.h-37 {
  height: 37px;
}
.h-36 {
  height: 36px;
}
.h-35 {
  height: 35px;
}
.h-34 {
  height: 34px;
}
.h-33 {
  height: 33px;
}
.h-32 {
  height: 32px;
}
.h-31 {
  height: 31px;
}
.h-30 {
  height: 30px;
}
.h-29 {
  height: 29px;
}
.h-28 {
  height: 28px;
}
.h-27 {
  height: 27px;
}
.h-26 {
  height: 26px;
}
.h-25 {
  height: 25px;
}
.h-24 {
  height: 24px;
}
.h-23 {
  height: 23px;
}
.h-22 {
  height: 22px;
}
.h-21 {
  height: 21px;
}
.h-20 {
  height: 20px;
}
.h-19 {
  height: 19px;
}
.h-18 {
  height: 18px;
}
.h-17 {
  height: 17px;
}
.h-16 {
  height: 16px;
}
.h-15 {
  height: 15px;
}
.h-14 {
  height: 14px;
}
.h-13 {
  height: 13px;
}
.h-12 {
  height: 12px;
}
.h-11 {
  height: 11px;
}
.h-10 {
  height: 10px;
}
.h-9 {
  height: 9px;
}
.h-8 {
  height: 8px;
}
.h-7 {
  height: 7px;
}
.h-6 {
  height: 6px;
}
.h-5 {
  height: 5px;
}
.h-4 {
  height: 4px;
}
.h-3 {
  height: 3px;
}
.h-2 {
  height: 2px;
}
.h-1 {
  height: 1px;
}
.w-full {
  width: 100%;
}
.w-0 {
  width: 0;
}
.w-1 {
  width: 8.33%;
}
.w-2 {
  width: 16.67%;
}
.w-3 {
  width: 25%;
}
.w-4 {
  width: 33.33%;
}
.w-5 {
  width: 41.67%;
}
.w-6 {
  width: 50%;
}
.w-7 {
  width: 58.33%;
}
.w-8 {
  width: 66.67%;
}
.w-9 {
  width: 75%;
}
.w-10 {
  width: 83.33%;
}
.w-11 {
  width: 91.67%;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-column {
  flex-direction: column;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-wrap {
  flex-wrap: wrap;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: end;
}
.justify-between {
  justify-content: space-between;
}
.overflow-auto {
  overflow: auto;
}
.overflow-auto-x {
  overflow-x: auto;
  overflow-y: hidden;
}
.overflow-auto-y {
  overflow-y: auto;
  overflow-x: hidden;
}
.overflow-hidden {
  overflow: hidden;
}
.border {
  border: 1px solid rgba(5, 5, 5, 0.06);
}
.border-bottom {
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}
.border-top {
  border-top: 1px solid rgba(5, 5, 5, 0.06);
}
.border-left {
  border-left: 1px solid rgba(5, 5, 5, 0.06);
}
.border-right {
  border-right: 1px solid rgba(5, 5, 5, 0.06);
}
.border-primary {
  border-color: #FA3534;
}
.line-height-1 {
  line-height: 1;
}
.line-height-101 {
  line-height: 1.1;
}
.line-height-102 {
  line-height: 1.2;
}
.line-height-103 {
  line-height: 1.3;
}
.bg-white {
  background-color: #ffffff;
}
.bg-primary {
  background-color: #FA3534;
}
.bg-primary-light {
  background-color: rgba(250, 53, 52, 0.06);
}
.text-color {
  color: var(--text-color);
}
.text-color-light-1 {
  color: var(--text-color-light-1);
}
.text-color-light-2 {
  color: var(--text-color-light-2);
}
.text-color-light-3 {
  color: var(--text-color-light-3);
}
.text-color-777 {
  color: #777;
}
.text-color-white {
  color: white;
}
.text-origin {
  color: #FF8731;
}
.text-blue {
  color: #055EAC;
}
.text-primary {
  color: #FA3534;
}
.cursor-pointer {
  cursor: pointer;
}
.hover-primary:hover {
  color: var(--primary-color);
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.relative {
  position: relative;
}
.box-shadow {
  box-shadow: var(--box-shadow);
}
.primary-color {
  color: var(--primary-color);
}
.block {
  display: block;
}
.ant-col.col-5 {
  flex: 0 0 20%;
}
.ant-col.col-3 {
  flex: 0 0 33%;
}
.clamp-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ant-input-number.w-full {
  width: 100%;
}
.ant-table-cell .ant-btn-link {
  padding: 0;
}
.ant-table-cell .ant-btn-link + .ant-btn-link {
  margin-left: 16px;
}
.ant-modal-header .ant-modal-title {
  font-weight: 700;
}
.ant-input-number.input-radius-8 {
  border-radius: 8px;
}
.ant-btn {
  border-radius: 8px;
}
.ant-descriptions .ant-descriptions-item-label {
  color: #777777;
}
.ant-descriptions .ant-descriptions-item-content {
  color: #333333;
}
.ant-card.no-border .ant-card-head {
  padding: 0;
  border-bottom: none;
}
.ant-card.no-border .ant-card-head-title {
  font-size: 16px;
  color: #333333;
  font-weight: 600;
}
.ant-card.no-border .ant-card-body {
  padding: 0;
}
.ant-form .ant-card + .ant-card {
  margin-top: 16px;
}
.ant-form .ant-form-item .ant-form-item-control-input-content .ant-picker {
  width: 100%;
}
.ant-drawer-body {
  background: #f5f8fa;
}
.ant-drawer-body .ant-card {
  border-radius: 12px;
}
* {
  box-sizing: border-box;
  margin: 0;
}
html,
body,
#root {
  height: 100%;
  background: #f5f8fa;
}
