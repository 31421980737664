@import "./variable.less";

.for-margin(50);
.for-padding(50);
.fontSize(44);
.radius(30);

.font-300 {
  font-weight: 300;
}
.font-400 {
  font-weight: 400;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}
.font-700 {
  font-weight: 700;
}

.h-full {
  height: 100%;
}

.h-0 {
  height: 0;
}

.height(50);

.w-full {
  width: 100%;
}

.w-0 {
  width: 0;
}
.w-1 {
  width: 8.33%;
}
.w-2 {
  width: 16.67%;
}
.w-3 {
  width: 25%;
}
.w-4 {
  width: 33.33%;
}
.w-5 {
  width: 41.67%;
}
.w-6 {
  width: 50%;
}
.w-7 {
  width: 58.33%;
}
.w-8 {
  width: 66.67%;
}
.w-9 {
  width: 75%;
}
.w-10 {
  width: 83.33%;
}
.w-11 {
  width: 91.67%;
}

.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}

.flex-column {
  flex-direction: column;
}

.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

.overflow-auto {
  overflow: auto;
}
.overflow-auto-x {
  overflow-x: auto;
  overflow-y: hidden;
}
.overflow-auto-y {
  overflow-y: auto;
  overflow-x: hidden;
}
.overflow-hidden {
  overflow: hidden;
}

.border {
  border: 1px solid @borderColor;
}

.border-bottom {
  border-bottom: 1px solid @borderColor;
}

.border-top {
  border-top: 1px solid @borderColor;
}

.border-left {
  border-left: 1px solid @borderColor;
}

.border-right {
  border-right: 1px solid @borderColor;
}

.border-primary {
  border-color: @primaryColor;
}

.line-height-1 {
  line-height: 1;
}

.line-height-101 {
  line-height: 1.1;
}

.line-height-102 {
  line-height: 1.2;
}

.line-height-103 {
  line-height: 1.3;
}

.bg-white {
  background-color: #ffffff;
}

.bg-primary {
  background-color: @primaryColor;
}

.bg-primary-light {
  background-color: @primaryColorLight1;
}

.text-color {
  color: var(--text-color);
}
.text-color-light-1 {
  color: var(--text-color-light-1);
}
.text-color-light-2 {
  color: var(--text-color-light-2);
}
.text-color-light-3 {
  color: var(--text-color-light-3);
}
.text-color-777 {
  color: #777;
}
.text-color-white {
  color: white;
}
.text-origin {
  color: @originColor;
}
.text-blue {
  color: @blueColor;
}
.text-primary {
  color: @primaryColor;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-primary {
  &:hover {
    color: var(--primary-color);
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.relative {
  position: relative;
}

.box-shadow {
  box-shadow: var(--box-shadow);
}

.primary-color {
  color: var(--primary-color);
}

.block {
  display: block;
}
.ant-col.col-5 {
  flex: 0 0 20%;
}
.ant-col.col-3 {
  flex: 0 0 33%;
}
.clamp-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

