.ant-input-number {
  &.w-full {
    width: 100%;
  }
}

.ant-table-cell {
  .ant-btn-link {
    padding: 0;
  }
  .ant-btn-link + .ant-btn-link {
    margin-left: 16px;
  }
}

.ant-modal-header .ant-modal-title {
  font-weight: 700;
}

.ant-input-number {
  &.input-radius-8 {
    border-radius: 8px;
  }
}
.ant-btn {
  border-radius: 8px;
}

.ant-descriptions {
  .ant-descriptions-item-label {
    color: #777777;
  }
  .ant-descriptions-item-content {
    color: #333333;
  }
}
.ant-card.no-border {
  .ant-card-head {
    padding: 0;
    border-bottom: none;
  }
  .ant-card-head-title {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
  }
  .ant-card-body {
    padding: 0;
  }
}

// 统一编辑区域样式
.ant-form {
  .ant-card + .ant-card {
    margin-top: 16px;
  }
  .ant-form-item {
    .ant-form-item-control-input-content {
      .ant-picker {
        width: 100%;
      }
    }
  }
}

.ant-drawer-body {
  background: #f5f8fa;
  .ant-card {
    border-radius: 12px;
  }
}
