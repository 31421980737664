:root {
  --primary-color: #FA3534;
  --primary-color-light-1: rgba(250, 53, 52, 0.06);
  --blue-color: #055EAC;
  --box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  --text-color: #434343;
  --text-color-light-1: #666666;
  --text-color-light-2: #4E5969;
  --text-color-light-3: #86909C;
}
.wrap {
  height: 100%;
  border-right: 1px solid rgba(5, 5, 5, 0.06);
  display: flex;
  flex-direction: column;
  position: relative;
}
.wrap.collapsed .head {
  padding-left: 18px;
  min-width: 60px;
}
.wrap :global .ant-menu {
  border-right: 0;
}
.wrap :global .ant-menu .ant-menu-vertical .ant-menu-item::after,
.wrap :global .ant-menu .ant-menu-vertical-left .ant-menu-item::after,
.wrap :global .ant-menu .ant-menu-vertical-right .ant-menu-item::after,
.wrap :global .ant-menu.ant-menu-inline .ant-menu-item::after {
  right: unset;
  left: 0;
}
.logo {
  height: 26px;
}
.head {
  min-width: 260px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  transition: all 0.2s;
}
.head h1 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 6px;
}
.collase {
  position: absolute;
  z-index: 101;
  text-align: center;
  border-radius: 40px;
  transition: transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--primary-color);
  opacity: 0.9;
  background-color: #ffffff;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(25, 15, 15, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  left: 100%;
  margin-left: 20px;
  top: 16px;
  font-size: 20px;
}
.collase:hover {
  opacity: 1;
}
.user {
  border-radius: 6px;
  padding: 8px;
  transition: all 0.1s;
  cursor: pointer;
}
.user:hover {
  background: rgba(0, 0, 0, 0.06);
}
.avatar {
  width: 26px;
}
.tagbox {
  overflow: hidden;
  height: 37px;
  transition: all 0.2s;
}
.tagbox.hidden {
  height: 0;
  padding: 0;
}
.tagbox :global .ant-tabs-nav {
  margin-bottom: 0!important;
}
.tagbox :global .ant-tabs-nav:before {
  border: none!important;
}
.tagbox :global .ant-tabs-nav .ant-tabs-nav-add {
  display: none;
}
.tagbox :global .ant-tabs-nav .ant-tabs-tab {
  background: transparent!important;
  border: 1px solid transparent!important;
  padding-left: 22px!important;
  padding: 4px 4px 4px 16px!important;
  border-radius: 6px 6px 0 0!important;
  margin-left: 0!important;
}
.tagbox :global .ant-tabs-nav .ant-tabs-tab:hover .ant-tabs-tab-remove {
  opacity: 1;
}
.tagbox :global .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  position: relative;
}
.tagbox :global .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab:before {
  content: '';
  position: absolute;
  border-left: 1px solid rgba(5, 5, 5, 0.06);
  height: 16px;
  width: 1px;
  left: 0;
}
.tagbox :global .ant-tabs-nav .ant-tabs-tab-remove {
  opacity: 0;
  margin-left: 0;
  padding: 1px 4px;
  font-size: 10px;
}
.tagbox :global .ant-tabs-nav .ant-tabs-tab-active {
  background: #f5f8fa !important;
  border-top: 1px solid rgba(5, 5, 5, 0.06) !important;
  border-left: 1px solid rgba(5, 5, 5, 0.06) !important;
  border-right: 1px solid rgba(5, 5, 5, 0.06) !important;
}
.tagbox :global .ant-tabs-nav .ant-tabs-tab-active:before,
.tagbox :global .ant-tabs-nav .ant-tabs-tab-active + .ant-tabs-tab:before {
  display: none;
}
.head-block {
  height: 50px;
  overflow: hidden;
  transition: all 0.1s;
}
.head-block.head-hidden {
  height: 0;
}
