@primaryColor: #FA3534;
@primaryColorLight1: rgba(@primaryColor, 0.06);
@originColor: #FF8731;
@blueColor: #055EAC;

@borderColor: rgba(5, 5, 5, 0.06);

:root {
  --primary-color: @primaryColor;
  --primary-color-light-1: @primaryColorLight1;
  --blue-color: @blueColor;
  --box-shadow: 0px 0px 12px rgba(0,0,0,0.12);

  --text-color: #434343;
  --text-color-light-1: #666666;
  --text-color-light-2: #4E5969;
  --text-color-light-3: #86909C;
}

.margin(@val) {
  .mt-@{val} {
    margin-top: ~"@{val}px";
  }
  .mb-@{val} {
    margin-bottom: ~"@{val}px";
  }
  .ml-@{val} {
    margin-left: ~"@{val}px";
  }
  .mr-@{val} {
    margin-right: ~"@{val}px";
  }
  .mlr-@{val} {
    margin-left: ~"@{val}px";
    margin-right: ~"@{val}px";
  }
  .mtb-@{val} {
    margin-top: ~"@{val}px";
    margin-bottom: ~"@{val}px";
  }
  .m-@{val} {
    margin: ~"@{val}px";
  }
}

.padding(@val) {
  .pt-@{val} {
    padding-top: ~"@{val}px";
  }
  .pb-@{val} {
    padding-bottom: ~"@{val}px";
  }
  .pl-@{val} {
    padding-left: ~"@{val}px";
  }
  .pr-@{val} {
    padding-right: ~"@{val}px";
  }
  .plr-@{val} {
    padding-left: ~"@{val}px";
    padding-right: ~"@{val}px";
  }
  .ptb-@{val} {
    padding-top: ~"@{val}px";
    padding-bottom: ~"@{val}px";
  }
  .p-@{val} {
    padding: ~"@{val}px";
  }
}

.for-margin(@index) when(@index > 0) {
  .margin(@index);
  .for-margin(@index - 1);
}

.for-padding(@index) when(@index > 0) {
  .padding(@index);
  .for-padding(@index - 1);
}

.fontSize(@index) when(@index > 0) {
  .font-@{index} {
    font-size: ~"@{index}px";
  }
  .fontSize(@index - 1);
}

.radius(@index) when(@index > 0) {
  .radius-@{index} {
    border-radius: ~"@{index}px";
  }
  .radius(@index - 1);
}

.height(@index) when(@index > 0) {
  .h-@{index} {
    height: ~"@{index}px";
  }
  .height(@index - 1);
}
